import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';

export const getManageCustomFiltersUrl = functionWithCondition(
	() =>
		// This is temporary - cleanup is scheduled for next week:
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('blu-7261-change-blu-5799-to-support-nav3') &&
		fg('blu-5799-jira-software-board-entrypoint-route'),
	getManageCustomFiltersUrlNew,
	getManageCustomFiltersUrlOld,
);

function getManageCustomFiltersUrlNew(
	projectKey: string,
	boardId: string,
	isCompanyManaged: boolean,
	isUserBoard: boolean,
	isJSMBoard?: boolean,
	// For testing purposes

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	pathname = window.location.pathname,
): string {
	const NEW_SETTINGS_PATH = '/settings/quickFilters';

	if (isJSMBoard) {
		// JSM Boards use their usual URL all the time.
		return `/jira/servicedesk/projects/${projectKey}/boards/${boardId}?config=quickFilters`;
	}

	if (isCompanyManaged) {
		if (isUserBoard) {
			return pathname.endsWith('/backlog')
				? `${pathname.replace('/backlog', '')}${NEW_SETTINGS_PATH}?from-backlog=1`
				: `${pathname}${NEW_SETTINGS_PATH}`;
		}

		return `/jira/software/c/projects/${projectKey}/boards/${boardId}${NEW_SETTINGS_PATH}${pathname.endsWith('backlog') ? '?from-backlog=1' : ''}`;
	}

	return `/jira/software/projects/${projectKey}/settings/boards/${boardId}/custom-filters`;
}

function getManageCustomFiltersUrlOld(
	projectKey: string,
	boardId: string,
	isCompanyManaged: boolean,
	isUserBoard: boolean,
	isJSMBoard?: boolean,
	// For testing purposes

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	pathname = window.location.pathname,
): string {
	if (isJSMBoard) {
		return `/jira/servicedesk/projects/${projectKey}/boards/${boardId}?config=quickFilters`;
	}

	if (isCompanyManaged) {
		if (isUserBoard) {
			return `${pathname}?config=quickFilters`;
		}

		return `/jira/software/c/projects/${projectKey}/boards/${boardId}${
			pathname.endsWith('backlog') ? '/backlog' : ''
		}?config=quickFilters`;
	}

	return `/jira/software/projects/${projectKey}/settings/boards/${boardId}/custom-filters`;
}
