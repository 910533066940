import { chain, set } from 'icepick';
import get from 'lodash/get';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type BoardDeferredDataLoadSuccessAction,
	BOARD_DEFERRED_DATA_LOAD_SUCCESS,
} from '../../../../actions/board/board-deferred-data/index.tsx';
import { CARD_MEDIA_TOGGLE_REQUEST } from '../../../../actions/board/menu/index.tsx';
import { RENAME_BOARD_REQUEST } from '../../../../actions/board/rename/index.tsx';
import type { Action } from '../../../../actions/index.tsx';
import { SOFTWARE_APP_LOADED } from '../../../../actions/software/index.tsx';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../../actions/work/index.tsx';
import type { ConfigState } from './types.tsx';

const initialState: ConfigState = {
	name: '',
	rankConfig: {
		boardIsRankable: true,
		rankCustomFieldId: 1234,
	},
	isInlineColumnEditEnabled: false,
	isCardMediaEnabled: false,
	isSprintsEnabled: false,
	isStandupsEnabled: false,
	isBacklogEnabled: false,
	isEstimationEnabled: false,
	isManageRulesEnabled: false,
	isReleasesFeatureEnabled: false,
	allFeatures: [],
	timeTrackingOptions: {
		workingHoursPerDay: 8,
		workingDaysPerWeek: 5,
		timeFormat: 'pretty',
		defaultUnit: 'minute',
	},
	isAnyStatusInCreateEnabled: true,
};

export const boardConfigReducer = (
	state: ConfigState = initialState,
	action: Action,
): ConfigState => {
	if (action.type === WORK_DATA_SET) {
		const {
			config,
			boardName,
			capabilities,
			boardConfig,
			inlineCardCreate,
			isInlineColumnEditEnabled,
			allFeatures,
		} = action.payload;

		const {
			isCardMediaEnabled,
			boardIssueListKey,
			isManageRulesEnabled,
			timeTrackingOptions,
			columnConstraintType,
			isAnyStatusInCreateEnabled,
		} = boardConfig;

		const newState = chain(state)
			.set('rankConfig', config)
			.set('name', boardName)
			.set('isBacklogEnabled', capabilities && capabilities.BACKLOG)
			.set('isSprintsEnabled', capabilities && capabilities.SPRINTS)
			.set(
				'isStandupsEnabled',
				fg('jira-warepil-standup-toggle') ? capabilities && capabilities.STANDUP : undefined,
			)
			.set('inlineCardCreate', inlineCardCreate)
			.set('isInlineColumnEditEnabled', isInlineColumnEditEnabled)
			.set('isCardMediaEnabled', isCardMediaEnabled)
			.set('boardIssueListKey', boardIssueListKey)
			.set('isManageRulesEnabled', isManageRulesEnabled)
			.set('isReleasesFeatureEnabled', capabilities && capabilities.RELEASES)
			.set('allFeatures', allFeatures)
			.set('timeTrackingOptions', timeTrackingOptions)
			.set('columnConstraintType', columnConstraintType)
			.set('isAnyStatusInCreateEnabled', isAnyStatusInCreateEnabled)
			.value();

		return newState;
	}

	if (action.type === WORK_DATA_CRITICAL_SET) {
		const {
			config,
			boardName,
			capabilities,
			inlineCardCreate,
			isInlineColumnEditEnabled,
			allFeatures,
		} = action.payload;

		const newState = chain(state)
			.set('rankConfig', config)
			.set('name', boardName)
			.set('isBacklogEnabled', capabilities && capabilities.BACKLOG)
			.set('isSprintsEnabled', capabilities && capabilities.SPRINTS)
			.set(
				'isStandupsEnabled',
				fg('jira-warepil-standup-toggle') ? capabilities && capabilities.STANDUP : undefined,
			)
			.set('isEstimationEnabled', capabilities && capabilities.ESTIMATION)
			.set('inlineCardCreate', inlineCardCreate)
			.set('isInlineColumnEditEnabled', isInlineColumnEditEnabled)
			.set('isReleasesFeatureEnabled', capabilities && capabilities.RELEASES)
			.set('allFeatures', allFeatures)
			.value();

		return newState;
	}

	if (action.type === CARD_MEDIA_TOGGLE_REQUEST) {
		return set(state, 'isCardMediaEnabled', action.payload);
	}

	if (action.type === RENAME_BOARD_REQUEST) {
		return set(state, 'name', action.payload);
	}

	if (action.type === SOFTWARE_APP_LOADED) {
		return set(
			state,
			'name',
			get(action.payload.prefetchData, ['boardData', 'boardName'], state.name),
		);
	}

	if (action.type === BOARD_DEFERRED_DATA_LOAD_SUCCESS) {
		const {
			payload: { isUsingSimplifiedWorkflow },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as BoardDeferredDataLoadSuccessAction;
		// For CMP boards, we allow inline column editing only with simplified workflow boards
		if (isUsingSimplifiedWorkflow !== undefined) {
			return set(state, 'isInlineColumnEditEnabled', isUsingSimplifiedWorkflow);
		}
	}

	return state;
};
