import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { Capability } from '../../../common/capability/index.tsx';
import { CAN_EDIT_BOARD } from '../../../model/permission/permission-types.tsx';
import { getPermissionsSelector } from '../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	isStandupsEnabled,
	operationsSelector,
} from '../../../state/selectors/board/board-selectors.tsx';
import { getCapability } from '../../../state/selectors/capability/capability-selectors.tsx';
import { firstIssueTypeIdSelector } from '../../../state/selectors/issue-type/issue-type-selectors.tsx';
import {
	getIsCMPBoard,
	projectKeySelector,
	rapidViewIdSelector,
	getBoardType,
} from '../../../state/selectors/software/software-selectors.tsx';
import { activeSprintsSelector } from '../../../state/selectors/sprint/sprint-selectors.tsx';
import { isReleasesFeatureEnabled } from '../../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../../state/types.tsx';
import Menu, { type StateProps } from './view.tsx';

export const mapStateToProps = (state: State): StateProps => {
	const permissions = getPermissionsSelector(state);
	const isCMPBoard = getIsCMPBoard(state);
	const boardType = getBoardType(state);

	return {
		isDisabled: !permissions[CAN_EDIT_BOARD] || isCMPBoard,
		projectKey: projectKeySelector(state),
		issueTypeId: firstIssueTypeIdSelector(state) || '',
		isEditSprintItemVisible: activeSprintsSelector(state)?.[0]?.canUpdateSprint ?? false,
		isCardMediaSwitchEnabled: false,
		isConfigureBoardEnabled: permissions[CAN_EDIT_BOARD] === true && !isCMPBoard,
		isManageCustomFiltersEnabled: permissions[CAN_EDIT_BOARD] === true && !isCMPBoard,
		boardId: rapidViewIdSelector(state),
		sprintId: activeSprintsSelector(state)?.[0]?.id,
		isReleaseVersionVisible:
			isCMPBoard &&
			boardType === 'KANBAN' &&
			isReleasesFeatureEnabled(state) &&
			getCapability(state)(Capability.RENDER_BOARD_RELEASE_MENU_BUTON),
		hasOperations: getWillShowNav4() && operationsSelector(state).length > 0,
		shouldShowStandupsFeature: fg('jira-warepil-standup-toggle') ? isStandupsEnabled(state) : true,
	};
};

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
