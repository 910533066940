import { JastBuilder } from '@atlaskit/jql-ast';
import { getSelectedValuesForField } from '@atlassian/jira-filter-refinement/src/controllers/ast/use-selected-values/index.tsx';
import {
	JqlClauseCollectingVisitor,
	type ClauseMap,
} from '@atlassian/jira-filter-refinement/src/controllers/ast/jql-clause-collecting-visitor/index.tsx';
import {
	ISSUE_PARENT,
	ISSUE_TYPE,
	SPRINT,
	TEXT,
	LABEL,
	VERSION,
} from '../../model/filter/filter-types.tsx';
import type { HydratedValue } from './types.tsx';

const SPRINT_TYPE = 'com.pyxis.greenhopper.jira:gh-sprint';

const getClauseMap = (jql: string) => {
	const ast = new JastBuilder().build(jql);
	let clauseMap: ClauseMap = {};
	if (ast.query) {
		({ clauseMap } = new JqlClauseCollectingVisitor().visit(ast.query));
	} else {
		throw new Error();
	}
	return clauseMap;
};

export const getSelectedFieldValues = (jqlContext: string, fieldName: string) => {
	const clauses = getClauseMap(jqlContext);
	return getSelectedValuesForField(clauses[fieldName], fieldName);
};

export const getFieldTypeName = (fieldType: string | null | undefined) => {
	switch (fieldType) {
		case SPRINT_TYPE:
			return SPRINT;
		case 'issuetype':
			return ISSUE_TYPE;
		case 'parent': {
			return ISSUE_PARENT;
		}
		case 'text': {
			return TEXT;
		}
		case 'labels': {
			return LABEL;
		}
		case 'fixVersions': {
			return VERSION;
		}
		default:
			return null;
	}
};

export const getFieldId = (item: HydratedValue, fieldType: String | null | undefined) => {
	switch (fieldType) {
		case SPRINT_TYPE: {
			return 'sprint' in item && item.sprint.sprintId;
		}
		case 'issuetype': {
			return 'issueTypes' in item && item.issueTypes[0].issueTypeId;
		}
		case 'parent': {
			return 'issue' in item && item.issue?.issueId;
		}
		case 'fixVersions': {
			return 'version' in item && item.version.versionId;
		}
		case 'text':
			return 'displayName' in item && item.displayName?.replace(/\*/g, '').trim();
		case 'labels': {
			return 'jqlTerm' in item && item.jqlTerm;
		}

		default:
			return item;
	}
};
