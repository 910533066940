import React, { useLayoutEffect, useRef } from 'react';
import Button from '@atlaskit/button/new';
import ErrorIcon from '@atlaskit/icon/core/error';
import { Text, Stack, xcss, Box } from '@atlaskit/primitives';
import {
	FireUiAnalytics,
	fireUIAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useIntl, type MessageDescriptorV2 } from '@atlassian/jira-intl';
import type { PopupHeight } from '../../../types.tsx';
import messages from './messages.tsx';

const POPUP_HEIGHT: PopupHeight = '380px';

export type ErrorType = 'fields' | 'fieldValues' | 'fieldValuesPagination';
export type Spacing = 'default' | 'compact';

type Props = {
	onClick: () => void;
	errorType: ErrorType;
	spacing?: Spacing;
	showSecondaryText?: boolean;
	buttonLabel?: MessageDescriptorV2;
	setHeight?: (height: number) => void;
};

export const ErrorMessage = ({
	onClick,
	errorType,
	spacing = 'default',
	showSecondaryText = true,
	buttonLabel,
	setHeight,
}: Props) => {
	const { formatMessage } = useIntl();

	const ref = useRef<HTMLDivElement>(null);

	// used for fieldValuesPagination error to calculate height of atlaskit select component
	useLayoutEffect(() => {
		if (ref && ref.current && setHeight) {
			setHeight(ref.current.clientHeight);
		}
	});

	return (
		<>
			<Stack
				alignInline="center"
				alignBlock="center"
				space={spacing === 'default' ? 'space.150' : 'space.0'}
				xcss={[containerStyles, spacing === 'default' ? defaultStyles : compactStyles]}
				ref={ref}
				testId="filter-refinement.ui.filter-popup.filter-popup-content.ui.error-message.stack"
			>
				<ErrorIcon label="" />
				<Box>
					<Box paddingBlock="space.100" paddingInline="space.400" xcss={[textCenterStyles]}>
						<Text>{formatMessage(messages.errorPrimaryMessage)}</Text>
					</Box>
					{showSecondaryText && (
						<Box paddingBlock="space.100" paddingInline="space.400" xcss={[textCenterStyles]}>
							<Text size="small" color="color.text.subtle">
								{formatMessage(messages.errorSecondaryMessage)}
							</Text>
						</Box>
					)}
				</Box>
				<Button
					onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'filterDialogErrorRefreshButton', {
							errorType,
						});
						onClick();
					}}
					spacing={spacing}
					testId="filter-refinement.ui.filter-popup.filter-popup-content.ui.error-message.button"
				>
					{formatMessage(buttonLabel || messages.retryButtonLabel)}
				</Button>
			</Stack>
			<FireUiAnalytics
				actionSubject="errorMessage"
				action="viewed"
				actionSubjectId="filterDialogErrorMessage"
				attributes={{ errorType }}
			/>
		</>
	);
};

const containerStyles = xcss({
	color: 'color.text.subtle',
});

const defaultStyles = xcss({
	paddingBlock: 'space.250',
	height: POPUP_HEIGHT,
});

const compactStyles = xcss({
	paddingTop: 'space.100',
});

const textCenterStyles = xcss({
	textAlign: 'center',
});
