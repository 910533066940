import {
	COMPOUND_OPERATOR_AND,
	COMPOUND_OPERATOR_OR,
	creators,
	type Jast,
	OPERATOR_EQUALS,
	OPERATOR_LIKE,
	print,
} from '@atlaskit/jql-ast';
import { ISSUE_KEY, TEXT_FIELDS } from '../../common/constants.tsx';
import {
	isIssueKeyValue,
	TextSearchInputVisitor,
} from '../../controllers/ast/text-search-input-visitor/index.tsx';
import type { TextSearchInputClauseType } from '../../controllers/ast/types.tsx';

export const wrapWithQuotes = (text: string) => (text ? `"${text.replace(/"/g, '\\"')}"` : '');

// TODO: escapeBackslashes serves as a temporary patch to convert jqlTerm into a format suitable for creator API.
// EM-6563 should resolve this by updating creator API to accept the semantic JQL value for jqlTerm currently returned by hydrateJqlQuery.
export const escapeBackslashes = (text: string) => text.replace(/\\/g, '\\\\');

const UNSUPPORTED_CHARACTERS = /[+\-&|!(){}[\]^~*?\\:]/g;
// JQL engine ignores + - & | ! ( ) { } [ ] ^ ~ * ? \ : special characters, so we need to remove them
// more info in https://confluence.atlassian.com/jirasoftwareserver/search-syntax-for-text-fields-939938747.html
const removeUnsupportedCharacters = (text: string) =>
	text.replace(UNSUPPORTED_CHARACTERS, ' ').trim();

// Returns the unsupported characters if the text is not an issue key
export const getUnsupportedCharacters = (text: string): string[] | null =>
	!isIssueKeyValue(text) ? text.match(UNSUPPORTED_CHARACTERS) : null;

export const addWildcard = (text: string) => {
	/*
	 * If the last character is a space, we don't need to add a wildcard
	 * because adding a wildcard after space will not match any issue
	 *
	 * If the last character is a quote, we don't need to add a wildcard
	 * this way we allow users to do exact match searches
	 */
	const lastChar = text[text.length - 1];
	if (!lastChar || lastChar === ' ' || lastChar === '"') {
		return text;
	}

	return `${text}*`;
};

/**
 * Check if search input clause is valid
 */
export const validate = (compoundClause: TextSearchInputClauseType): boolean => {
	if (!compoundClause) {
		return true;
	}
	try {
		compoundClause.accept(new TextSearchInputVisitor());
		return true;
	} catch (e) {
		return false;
	}
};

export const getValue = (
	textSearchInputClause: TextSearchInputClauseType,
	valueWithSpecialCharacters?: string,
) => {
	if (!textSearchInputClause) {
		return valueWithSpecialCharacters || undefined;
	}

	let quotedKeyword = textSearchInputClause.accept(new TextSearchInputVisitor());

	if (quotedKeyword && quotedKeyword[quotedKeyword.length - 1] === '*') {
		quotedKeyword = quotedKeyword.slice(0, -1);
	}

	if (!valueWithSpecialCharacters) {
		return quotedKeyword;
	}

	return removeUnsupportedCharacters(valueWithSpecialCharacters) === quotedKeyword
		? valueWithSpecialCharacters
		: quotedKeyword;
};

const createTextSearchInputClause = ({
	quotedKeyword,
	isIssueKey,
}: {
	quotedKeyword: string;
	isIssueKey: boolean;
}): NonNullable<TextSearchInputClauseType> => {
	const textSearchInputClause = creators.terminalClause(
		creators.field(TEXT_FIELDS),
		creators.operator(OPERATOR_LIKE),
		creators.byText.valueOperand(quotedKeyword),
	);

	if (isIssueKey) {
		const issueKeyClause = creators.terminalClause(
			creators.field(ISSUE_KEY),
			creators.operator(OPERATOR_EQUALS),
			creators.byText.valueOperand(quotedKeyword),
		);

		return creators.compoundClause(creators.compoundOperator(COMPOUND_OPERATOR_OR), [
			textSearchInputClause,
			issueKeyClause,
		]);
	}

	return textSearchInputClause;
};

const getQuotedKeyword = (
	val: string,
): { quotedKeyword: string | undefined; isIssueKey: boolean } => {
	if (val === undefined || val === null || val === '') {
		return { quotedKeyword: undefined, isIssueKey: false };
	}

	const isIssueKey = isIssueKeyValue(val);
	const quotedKeyword = isIssueKey
		? // When value is an issue key, we need to wrap it with quotes
			wrapWithQuotes(escapeBackslashes(val))
		: // For any other value, we need to also add a wildcard and remove unsupported characters
			wrapWithQuotes(addWildcard(escapeBackslashes(removeUnsupportedCharacters(val))));

	return { quotedKeyword, isIssueKey };
};

/**
 * Update search input clause with new value
 */
export const update = (
	ast: Jast,
	textSearchInputClause: TextSearchInputClauseType,
	val: string,
): string => {
	if (!validate(textSearchInputClause)) {
		return ast.represents;
	}

	const { quotedKeyword, isIssueKey } = getQuotedKeyword(val);

	if (quotedKeyword) {
		const newTextSearchInputClause = createTextSearchInputClause({ quotedKeyword, isIssueKey });

		if (textSearchInputClause) {
			textSearchInputClause.replace(newTextSearchInputClause);
		} else {
			ast.query?.appendClause(newTextSearchInputClause, COMPOUND_OPERATOR_AND);
		}
	} else if (textSearchInputClause) {
		textSearchInputClause.remove();
	} else {
		return ast.represents;
	}
	return print(ast, {
		operatorCase: 'upper',
	});
};
