import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dropdownMenuAriaLabelOld: {
		id: 'software.main.board-container.board.column.header.menu.dropdown-menu-aria-label-old',
		defaultMessage: '{heading} column',
		description: 'Aria Label for dropdown menu button',
	},
	dropdownMenuAriaLabel: {
		id: 'software.main.board-container.board.column.header.menu.dropdown-menu-aria-label',
		defaultMessage: '{heading} column more actions',
		description: 'Aria Label for dropdown menu button',
	},
	moreActionsTooltip: {
		id: 'software.main.board-container.board.column.header.menu.more-actions-tooltip',
		defaultMessage: 'More actions',
		description: 'Tooltip for dropdown menu button in software board column header',
	},
});
