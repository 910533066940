import React from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { Box, Inline, Stack, xcss, media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import { VIEW_SETTINGS_PANEL_CLOSE_BUTTON_TEST_ID } from '../../constants.tsx';
import messages from './messages.tsx';
import type { PanelProps } from './types.tsx';

export const Panel = ({ title = '', onClose = noop, children }: PanelProps) => {
	const { formatMessage } = useIntl();
	const headerTitleId = 'view-settings.ui.panel.header-title';

	const { isSoftwareFullTheming } = useSoftwareProjectTheming();

	return (
		<Stack alignInline="end">
			<Box
				xcss={[panelStyles, isSoftwareFullTheming && dialogStyles]}
				role="dialog"
				aria-modal="false"
				aria-labelledby={headerTitleId}
			>
				<Inline alignBlock="center" grow="fill" spread="space-between" xcss={headerStyles}>
					<Heading
						size={fg('view-settings-title-size-fix') ? 'small' : 'medium'}
						as="h2"
						id={headerTitleId}
					>
						{title || formatMessage(messages.defaultTitle)}
					</Heading>
					<Box xcss={ButtonWrapperStyles}>
						<Button
							appearance="subtle"
							spacing="none"
							onClick={onClose}
							testId={VIEW_SETTINGS_PANEL_CLOSE_BUTTON_TEST_ID}
						>
							<Box xcss={IconWrapperStyles}>
								<CrossIcon spacing="spacious" label={formatMessage(messages.closeButtonLabel)} />
							</Box>
						</Button>
					</Box>
				</Inline>
				<Stack>{children}</Stack>
			</Box>
		</Stack>
	);
};

const headerStyles = xcss({
	marginBottom: 'space.100',
});
const panelStyles = xcss({
	border: `1px solid ${token('color.border')}`,
	borderRadius: 'border.radius.200',
	width: '280px',
	padding: 'space.300',
	[media.above.lg]: {
		width: '300px',
	},
});

const ButtonWrapperStyles = xcss({
	width: token('space.400'),
	height: token('space.400'),
});

const IconWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: token('space.400'),
	height: token('space.400'),
});

const dialogStyles = xcss({
	background: token('elevation.surface'),
	boxShadow: 'elevation.shadow.overlay',
});
