import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import {
	DONE,
	statusCategoryForId,
	StatusCategoryIds,
	type StatusCategoryKeys,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { measureFunc } from '@atlassian/jira-common-performance/src/marks.tsx';
import { assertDefined } from '@atlassian/jira-fallback-detection/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { type Color, COLORS, PURPLE } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import { getHierarchyLevelType } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import type {
	MobileColumn,
	MobileRestApiResponse,
	MobileProject,
	MobileTransitionsPerIssueType,
	MobileStatus,
	MobileTransition,
	MobileIssueTypes,
	MobileIssue,
} from '@atlassian/jira-software-board-uif-types/src/index.tsx';
import type {
	CardTransitionEntities,
	ColumnIssueTypeTransitions,
	IssueTypeStatus,
	IssueTypeTransitions,
} from '../../../../../model/card-transition/card-transition-types.tsx';
import type { Column, StatusColumn } from '../../../../../model/column/column-types.tsx';
import { PANDORA_TEAM_NAME } from '../../../../../model/constants.tsx';
import type { IssueMediaCollection } from '../../../../../model/issue/issue-media-types.tsx';
import type { Issue, IssueParent, CoreIssue } from '../../../../../model/issue/issue-types.tsx';
import type { Person } from '../../../../../model/people/people-types.tsx';
import type {
	CardType,
	IssueProject,
	Status,
	RequestType,
	boardLocation,
} from '../../../../../model/software/software-types.tsx';
import type { SprintArray } from '../../../../../model/sprint/sprint-types.tsx';
import {
	NO_SWIMLANE,
	SWIMLANE_BY_JQL,
	SWIMLANE_BY_ASSIGNEE,
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_BY_PROJECT,
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST,
	SWIMLANE_BY_REQUEST_TYPE,
} from '../../../../../model/swimlane/swimlane-modes.tsx';
import type {
	BoardConfig,
	ColumnConstraintType,
	RankConfigData,
} from '../../../../../model/work/config-types.tsx';
import type { JQLSwimlaneData, WorkData } from '../../../../../model/work/work-types.tsx';
import { getHideEpics } from '../../../../software/software-storage-global.tsx';
import { transformIssue } from './issue/index.tsx';

export function transformProjects(
	mobileResponse: Pick<MobileRestApiResponse, 'projects'>,
): IssueProject[] {
	return Object.values(mobileResponse.projects).map((project) => {
		const { id, name, key, avatar, permissions, projectTypeKey, canRank } = project;
		return {
			id,
			name,
			key,
			avatar,
			permissions,
			projectTypeKey,
			canRank,
		};
	});
}

export const transformBoardLocation = (
	mobileResponse: Pick<MobileRestApiResponse, 'boardLocation'>,
): boardLocation => {
	const { boardLocation } = mobileResponse;
	return {
		type: boardLocation.type,
		id: boardLocation.id,
		name: boardLocation.name,
		avatarUrl: boardLocation.avatarUrl,
		key: boardLocation.key,
		contextualKey: boardLocation.contextualKey,
	};
};

const GHX_TO_EPIC_COLOR: { [color: string]: Color } = {
	'ghx-label-1': 'DARK_GREY',
	'ghx-label-2': 'DARK_YELLOW',
	'ghx-label-3': 'YELLOW',
	'ghx-label-4': 'DARK_BLUE',
	'ghx-label-5': 'DARK_TEAL',
	'ghx-label-6': 'GREEN',
	'ghx-label-7': 'PURPLE',
	'ghx-label-8': 'DARK_PURPLE',
	'ghx-label-9': 'ORANGE',
	'ghx-label-10': 'BLUE',
	'ghx-label-11': 'TEAL',
	'ghx-label-12': 'GREY',
	'ghx-label-13': 'DARK_GREEN',
	'ghx-label-14': 'DARK_ORANGE',
};

export function ghxColorToEpicColor(color: string): Color {
	assertDefined(
		GHX_TO_EPIC_COLOR[color],
		'boardEpicGhxColorAssert',
		'jiraSoftwareBoard',
		PANDORA_TEAM_NAME,
	);
	return GHX_TO_EPIC_COLOR[color] ?? PURPLE;
}

function isValidEpicColor(color: string): color is Color {
	const colorsAsStrings: readonly string[] = COLORS;
	return colorsAsStrings.includes(color);
}

export function issueParentColorToEpicColor(color?: string): Color {
	assertDefined(color, 'boardEpicColorAssert', 'jiraSoftwareBoard', PANDORA_TEAM_NAME);
	if (!color) {
		return PURPLE;
	}
	const upperCaseColor = color?.toUpperCase();
	assertDefined(
		upperCaseColor,
		'boardUpperCaseEpicColorAssert',
		'jiraSoftwareBoard',
		PANDORA_TEAM_NAME,
		false,
		(value) => isValidEpicColor(value),
	);
	return isValidEpicColor(upperCaseColor) ? upperCaseColor : PURPLE;
}

export function transformIssueParents(
	mobileResponse: Pick<MobileRestApiResponse, 'epicIssues'>,
): IssueParent[] {
	return mobileResponse.epicIssues.map((epic) => ({
		id: epic.id,
		key: epic.key,
		// This might be okay to remove when https://app.launchdarkly.com/jira/staging/features/epic-related-fields-deprecation-main-flag_plni4/targeting
		// is fully rolled-out.
		summary: epic.summary || epic.epicName,
		status: epic.status ? transformStatus(epic.status) : undefined,
		issueType: {
			id: String(epic.issueType.id),
			iconUrl: epic.issueType.iconUrl,
			name: epic.issueType.name,
			hierarchyLevel: epic.issueType.hierarchyLevel,
		},
		color: fg('jsw-epic-panel-return-non-system-epics')
			? issueParentColorToEpicColor(epic.color)
			: ghxColorToEpicColor(epic.color),
		flagged: epic.flagged,
	}));
}

export function transformIssueTypes(
	mobileResponse: Pick<MobileRestApiResponse, 'issueTypes'>,
): CardType[] {
	return Object.keys(mobileResponse.issueTypes).map((issueTypeId) => {
		const issueType = mobileResponse.issueTypes[issueTypeId];
		return {
			id: String(issueType.id),
			name: issueType.name,
			iconUrl: issueType.iconUrl,
			hierarchyLevelType: getHierarchyLevelType(issueType.hierarchyLevel),
			default: issueType.default,
			// TODO: Missing issue type required fields and description
			hasRequiredFields: false,
			// so messy type, why is this required? crying....
			description: 'WHAT',
		};
	});
}

export function transformRequestTypes(
	mobileResponse: Pick<MobileRestApiResponse, 'requestTypes'>,
): RequestType[] {
	return Object.keys(mobileResponse.requestTypes).map((requestTypeId) => {
		const { id, name, iconUrl } = mobileResponse.requestTypes[requestTypeId];
		return {
			id,
			name,
			iconUrl,
		};
	});
}

export function transformStatus(mobileStatus: MobileStatus): Status {
	return {
		id: Number(mobileStatus.id),
		name: mobileStatus.name,
		category: statusCategoryForId(mobileStatus.statusCategory.id),
	};
}

export function transformStatuses(
	mobileResponse: Pick<MobileRestApiResponse, 'statuses'>,
): Status[] {
	return Object.values(mobileResponse.statuses).map((status) => transformStatus(status));
}

// TODO: Should we run unique function on the types
export function transformTransitionsByIssueType(
	mobileResponse: Pick<MobileRestApiResponse, 'projects'>,
): MobileTransitionsPerIssueType {
	const transitionsByIssueType: MobileTransitionsPerIssueType = {};
	Object.values(mobileResponse.projects).forEach((project: MobileProject) => {
		Object.keys(project.transitionsPerIssueType).forEach((key) => {
			if (!transitionsByIssueType[key]) {
				transitionsByIssueType[key] = [];
			}
			transitionsByIssueType[key].push(...project.transitionsPerIssueType[key]);
		});
	});
	return transitionsByIssueType;
}

export function transformIssueTypeStatus(
	mobileResponse: Pick<MobileRestApiResponse, 'projects' | 'columns' | 'issueTypes'>,
): IssueTypeStatus {
	const allStatuses = mobileResponse.columns.flatMap((column) => column.statuses);
	const statusesById: { [key: string]: MobileStatus } = keyBy(allStatuses, 'id');
	const transitionsByIssueType: MobileTransitionsPerIssueType =
		transformTransitionsByIssueType(mobileResponse);

	const issueTypeStatus: IssueTypeStatus = {};
	Object.keys(mobileResponse.issueTypes).forEach((issueTypeId) => {
		const issueType = mobileResponse.issueTypes[issueTypeId];
		const transitions = transitionsByIssueType[issueType.id];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		issueTypeStatus[issueType.id] = {} as { [statusId: string]: Status };
		transitions?.forEach((transition) => {
			const status = statusesById[transition.toStatusId];
			if (!status) {
				return;
			}
			issueTypeStatus[issueType.id][transition.toStatusId] = {
				id: Number(status.id),
				name: status.name,
				category: statusCategoryForId(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					StatusCategoryIds[status.statusCategory.key as StatusCategoryKeys],
				),
			};
		});
	});
	return issueTypeStatus;
}

export function transformTransitionsForSingleColumn(
	column: MobileColumn,
	transitionsByDestinationStatus: {
		[statusId: string]: {
			transition: MobileTransition;
			issueTypeId: string;
			projectKey: string;
		}[];
	},
): IssueTypeTransitions {
	const current: IssueTypeTransitions = {};

	// The status ID here is a destination status ID, is there any reason to map
	// it against transitions by the source
	column.statusIds.forEach((statusId) => {
		const transitions = [...(transitionsByDestinationStatus[statusId] || [])];
		transitions.forEach(({ issueTypeId, transition, projectKey }) => {
			if (!current[issueTypeId]) {
				current[issueTypeId] = [];
			}

			// Don't allow duplicates if they have the same transition ID, project key and
			// source/destination status.
			// In JSM, it is entirely possible for transitions to have the same ID but different
			// source and destination statuses.
			// For CMP boards you can have multiple boards per project
			if (
				current[issueTypeId].some(
					(issueTransition) =>
						issueTransition.id === transition.transitionId &&
						issueTransition.projectKey === projectKey &&
						issueTransition.sourceStatusId === Number(transition.fromStatusId) &&
						issueTransition.destinationStatusId === Number(transition.toStatusId),
				)
			) {
				// transition already added to issueTypeId, avoid duplicate entry
				return;
			}

			current[issueTypeId].push({
				id: transition.transitionId,
				name: transition.name,
				sourceStatusId: Number(transition.fromStatusId),
				destinationStatusId: Number(transition.toStatusId),
				isGlobal: transition.global,
				isInitial: transition.initial,
				hasConditions: transition.hasConditions,
				hasScreen: transition.hasScreen,
				projectKey,
			});
		});
	});

	return current;
}

export function transformColumnIssueTypeTransitions(
	mobileResponse: Pick<MobileRestApiResponse, 'projects' | 'columns'>,
): ColumnIssueTypeTransitions {
	// Note this contains duplicate entries for the same transitions if they
	// appear on multiple projects.
	const allTransitions: {
		transition: MobileTransition;
		issueTypeId: string;
		projectKey: string;
	}[] = Object.values(mobileResponse.projects).flatMap((project) =>
		Object.keys(project.transitionsPerIssueType).flatMap((issueTypeId) =>
			project.transitionsPerIssueType[issueTypeId].map((transition) => ({
				transition,
				issueTypeId,
				projectKey: project.key,
			})),
		),
	);
	const transitionsByDestinationStatus = groupBy(allTransitions, (t) => t.transition.toStatusId);

	const columnIssueTypeTransitions: ColumnIssueTypeTransitions = {};
	mobileResponse.columns.forEach((column) => {
		const current = transformTransitionsForSingleColumn(column, transitionsByDestinationStatus);
		columnIssueTypeTransitions[column.id] = current;
	});
	return columnIssueTypeTransitions;
}

export function transformCardTransitions(
	mobileResponse: MobileRestApiResponse,
): CardTransitionEntities {
	const issueTypeStatus = transformIssueTypeStatus(mobileResponse);

	const columnIssueTypeTransitions = transformColumnIssueTypeTransitions(mobileResponse);
	const cardTransitions = {
		columnIssueTypeTransitions,
		issueTypeStatus,
	};
	return cardTransitions;
}

export function transformColumns(
	mobileResponse: Pick<MobileRestApiResponse, 'columns' | 'statisticsConfig'>,
	columnTransitions: ColumnIssueTypeTransitions,
): StatusColumn[] {
	const isColumnConstraintSet =
		mobileResponse.statisticsConfig?.typeId && mobileResponse.statisticsConfig.typeId !== 'none';

	return mobileResponse.columns.map((column) => ({
		type: ColumnType.STATUS,
		id: column.id,
		maxIssueCount: isColumnConstraintSet ? column.maxLimit ?? null : null,
		minIssueCount: isColumnConstraintSet ? column.minLimit ?? null : null,
		limitJql: column.limitJql,
		name: column.name,
		statuses: column.statuses.map((status): Status => {
			const category = statusCategoryForId(
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				StatusCategoryIds[status.statusCategory.key as StatusCategoryKeys],
			);
			return {
				id: Number(status.id),
				name: status.name,
				category,
				isDone: category === DONE,
				isInitial: Object.values(columnTransitions[column.id])
					.flatMap((t) => t)
					.some(({ isInitial }) => isInitial),
			};
		}),
	}));
}

export function transformSprints(
	mobileResponse: Pick<MobileRestApiResponse, 'sprints'>,
): SprintArray {
	return (
		mobileResponse.sprints?.map((sprint) => ({
			id: sprint.id,
			name: sprint.name,
			startDate: sprint.isoStartDate,
			endDate: sprint.isoEndDate,
			canUpdateSprint: sprint.canUpdateSprint,
			daysRemaining: sprint.daysRemaining,
			goal: sprint.goal,
			state: sprint.state,
		})) ?? []
	);
}

const CMP_SWIMLANE_STRATEGY_TO_UIF_ID: { [key: string]: SwimlaneModeId } = {
	assignee: SWIMLANE_BY_ASSIGNEE.id,
	assigneeUnassignedFirst: SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST.id,
	parentChild: SWIMLANE_BY_SUBTASK.id,
	epic: SWIMLANE_BY_PARENT_ISSUE.id,
	none: NO_SWIMLANE.id,
	custom: SWIMLANE_BY_JQL.id,
	project: SWIMLANE_BY_PROJECT.id,
	request_type: SWIMLANE_BY_REQUEST_TYPE.id,
};

export function transformSwimlaneModeId(swimlaneStrategy: string): SwimlaneModeId {
	return CMP_SWIMLANE_STRATEGY_TO_UIF_ID[swimlaneStrategy] ?? NO_SWIMLANE.id;
}

export function transformJqlSwimlanes(
	mobileResponse: Pick<MobileRestApiResponse, 'swimlaneInfo'>,
): JQLSwimlaneData[] | undefined {
	return mobileResponse.swimlaneInfo.swimlaneStrategy === 'custom'
		? mobileResponse.swimlaneInfo.swimlanes?.map(
				(swimlane): JQLSwimlaneData => ({
					id: String(swimlane.id),
					name: swimlane.name,
					query: swimlane.query ?? 'true',
					description: swimlane.description,
					issueIds: swimlane.issueIds,
					isDefaultSwimlane: swimlane.defaultSwimlane,
				}),
			)
		: undefined;
}

export const isDoneColumn = (column: MobileColumn): boolean =>
	column.statuses?.every((status) => status.statusCategory.key === 'done');

export const filterIssueTypeRequirement = (
	issueTypesById: MobileIssueTypes,
	issueTypeId: string,
): boolean => issueTypesById[issueTypeId] != null;

export function transformIssues(mobileResponse: MobileRestApiResponse): {
	issues: Issue[];
	issueChildren: Issue[];
	orderedIssueIds: number[];
	missingIssueType: string;
} {
	const epicsByKey = keyBy(mobileResponse.epicIssues, 'key');
	const issueTypesById = mobileResponse.issueTypes;
	const prioritiesById = mobileResponse.priorities;
	const isChildIssueType = (issueTypeId: number) =>
		!!mobileResponse.issueTypes[issueTypeId]?.subtask;
	let missingIssueType = '';
	const allIssuesById = new Map(
		mobileResponse.columns.flatMap((column) =>
			column.issues
				.filter((issue) => {
					const requirement = filterIssueTypeRequirement(issueTypesById, issue.issueTypeId);
					if (!requirement) missingIssueType = issue.issueTypeId;
					return requirement;
				})
				.map((issue) => [
					issue.id,
					transformIssue({
						issue,
						estimation: mobileResponse.estimation,
						column,
						epicsByKey,
						issueTypesById,
						prioritiesById,
						sprints: mobileResponse.sprints,
					}),
				]),
		),
	);

	const issues: Issue[] = [];
	const issueChildren: Issue[] = [];
	// We also filter out issue ids that are with missing issue types.
	const orderedIssueIds: number[] = [];

	mobileResponse.orderedIssueIds.forEach((id) => {
		const issue = allIssuesById.get(id);

		if (!issue) return;

		if (isChildIssueType(issue.typeId)) {
			issueChildren.push(issue);
		} else {
			// This is actually issue + issue parent
			// We need to render epics in CMP board except for swimlane by epic, so issue parents are included here with retaining the order.
			issues.push(issue);
		}

		orderedIssueIds.push(id);
	});

	return { issues, issueChildren, orderedIssueIds, missingIssueType };
}

export function transformColumnConstraintType({
	typeId,
}: MobileRestApiResponse['statisticsConfig']): ColumnConstraintType | undefined {
	switch (typeId) {
		case 'issueCount':
			return 'ISSUE_COUNT';
		case 'issueCountExclSubs':
			return 'ISSUE_COUNT_EXCLUDE_SUBTASKS';
		default:
			return undefined;
	}
}

export function transformMissingParents(
	mobileResponse: Pick<MobileRestApiResponse, 'missingParents'>,
): CoreIssue[] {
	const { missingParents } = mobileResponse;
	if (!missingParents) return [];

	return missingParents.map((missingParent) => {
		const { id, key, summary, assigneeAccountId, parentId, typeUrl, status, projectId, typeId } =
			missingParent;

		const category = statusCategoryForId(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			StatusCategoryIds[status.statusCategory.key as StatusCategoryKeys],
		);

		return {
			id,
			key,
			summary,
			assigneeAccountId,
			typeUrl,
			parentId,
			status: {
				id: Number(status.id),
				name: status.name,
				category,
				isDone: category === DONE,
				isInitial: false,
			},
			projectId,
			typeId: Number(typeId),
			statusId: Number(status.id),
		};
	});
}

export const transformRankConfigData = (
	mobileResponse: Pick<MobileRestApiResponse, 'projects' | 'rankable' | 'rankingCustomField'>,
): RankConfigData => {
	// for a multi-project CMP boards, check if any of the projects can rank
	const canUserRank = Object.values(mobileResponse.projects).some((project) => project.canRank);

	return {
		boardIsRankable: mobileResponse.rankable && canUserRank,
		rankCustomFieldId: canUserRank ? Number(mobileResponse.rankingCustomField) : NaN,
	};
};

export const getCardMediaFromColumnIssues = ({
	columns,
	media,
}: Pick<MobileRestApiResponse, 'columns' | 'media'>): IssueMediaCollection => {
	// We can potentially do nothing if card media is turned off as well
	if (isEmpty(columns) || isEmpty(media)) return {};

	return columns
		.flatMap((column) => column.issues)
		.reduce((map, issue: MobileIssue): IssueMediaCollection => {
			const { cardMedia } = issue;
			if (!cardMedia) {
				return Object.assign(map, {
					[String(issue.id)]: null,
				});
			}
			return Object.assign<IssueMediaCollection, IssueMediaCollection>(map, {
				[String(issue.id)]: {
					attachmentId: String(cardMedia.attachmentId),
					attachmentMediaApiId: cardMedia.mediaApiFileId,
					endpointUrl: media?.endpointUrl,
					clientId: media?.clientId,
					isHiddenByUser: false,
					token: cardMedia.mediaReadToken,
				},
			});
		}, {});
};

/**
 * Transforming the CMP mobile REST endpoint response into `WorkData`.
 */
export function transformMobileResponse(json: unknown): WorkData {
	return measureFunc('uif-board-transformMobileResponse', () => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const mobileResponse = json as MobileRestApiResponse;
		const { issues, issueChildren, orderedIssueIds, missingIssueType } =
			transformIssues(mobileResponse);

		const people: Person[] = Object.values(mobileResponse.people).map((person) => ({
			...person,
			id: person.accountId,
		}));
		const cardTransitions = transformCardTransitions(mobileResponse);
		const columns: Column[] = transformColumns(
			mobileResponse,
			cardTransitions.columnIssueTypeTransitions,
		);
		const issueParents = transformIssueParents(mobileResponse);
		const issueTypes = transformIssueTypes(mobileResponse);
		const requestTypes = transformRequestTypes(mobileResponse);
		const statuses = transformStatuses(mobileResponse);

		const projects = transformProjects(mobileResponse);
		const boardLocation = transformBoardLocation(mobileResponse);
		const capabilities = {
			BACKLOG: mobileResponse.capabilities.BACKLOG.enabled,
			SPRINTS: mobileResponse.capabilities.SPRINTS.enabled,
			REPORTS: mobileResponse.capabilities.REPORTS.enabled,
			ESTIMATION: mobileResponse.capabilities.ESTIMATION.enabled,
			RELEASES: mobileResponse.capabilities.RELEASES.enabled,
		};
		const boardConfig: BoardConfig = {
			isCardMediaEnabled: false,
			boardIssueListKey: undefined,
			requestColumnMigration: undefined,
			isSprintsEnabled: false,
			isBacklogEnabled: false,
			issuesCountByColumn: {},
			isManageRulesEnabled: false,
			areEpicLabelsHidden: getHideEpics(mobileResponse.id),
			columnConstraintType: transformColumnConstraintType(mobileResponse.statisticsConfig),
		};

		const sprints = transformSprints(mobileResponse);
		const swimlaneModeId = transformSwimlaneModeId(mobileResponse.swimlaneInfo.swimlaneStrategy);
		return {
			issues,
			people,
			projects,
			boardLocation,
			boardConfig,
			issueMedia: getCardMediaFromColumnIssues(mobileResponse),
			config: transformRankConfigData(mobileResponse),
			hasFilteredIssues: false,
			columns,
			swimlaneModeId,
			adminConfiguredSwimlaneModeId: swimlaneModeId,
			sprints,
			isSprintsEnabled: (sprints?.length ?? 0) > 0,
			boardName: mobileResponse.name,
			capabilities,
			issueParents,
			issueTypes,
			projectIssueTypes: issueTypes,
			requestTypes,
			statuses,
			boardPermissions: {
				createIssue: projects[0]?.permissions?.CREATE_ISSUES ?? true,
				// TODO: per issue permissions
				// ! project permissions doesn't include EDIT_ISSUES so will assume create issue at the moment
				editIssue: projects[0]?.permissions?.CREATE_ISSUES ?? true,
				// TODO: per issue permissions???
				deleteIssue: projects[0]?.permissions?.DELETE_ISSUES ?? true,
				archiveIssue: projects[0]?.permissions?.ARCHIVE_ISSUES ?? true,
				manageSprint: sprints.some((sprint) => sprint.canUpdateSprint),
				editBoardConfig: mobileResponse.canEdit,
				manageAutomation: true,
				canRelease: mobileResponse.canRelease,
			},
			issueChildren,
			cardTransitions,
			inlineCardCreate: mobileResponse.inlineCardCreate,
			isInlineColumnEditEnabled: mobileResponse.isUsingSimplifiedWorkflow === true,
			// TODO: Missing features
			allFeatures: [
				{
					key: 'BACKLOG',
					status: mobileResponse.capabilities.BACKLOG.enabled ? 'ENABLED' : 'DISABLED',
					toggle: mobileResponse.capabilities.BACKLOG.enabled ? 'ENABLED' : 'DISABLED',
					category: 'CMP STUFF',
				},
				{
					key: 'SPRINTS',
					status: mobileResponse.capabilities.SPRINTS.enabled ? 'ENABLED' : 'DISABLED',
					toggle: mobileResponse.capabilities.SPRINTS.enabled ? 'ENABLED' : 'DISABLED',
					category: 'CMP STUFF',
				},
			],
			// THIS MUST BE NULL
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			estimationStatistic: null as any,
			customFilters: mobileResponse.filters?.map((filter) => ({
				id: String(filter.id),
				name: filter.name,
				description: filter.description,
			})),
			jqlSwimlanes: transformJqlSwimlanes(mobileResponse),
			boardType: mobileResponse.type === 'SCRUM' ? 'SCRUM' : 'KANBAN',
			etag: mobileResponse.etag,
			orderedIssueIds,
			missingParents: transformMissingParents(mobileResponse),
			hierarchyNomenclature: mobileResponse?.flexibleNomenclatureData?.levelOneName
				? {
						firstLevelName: mobileResponse.flexibleNomenclatureData.levelOneName,
					}
				: null,
			issueLimitExceeded: mobileResponse.issueLimitExceeded,
			savedFilterId: mobileResponse.savedFilterId,
			missingIssueType,
		};
	});
}
