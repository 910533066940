import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button/new';
import FilterIcon from '@atlaskit/icon/core/filter';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import type { JiraJqlScopeInput } from '@atlassian/jira-relay/src/__generated__/FilterPopupContentQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	fireUIAnalytics,
	type UIAnalyticsEvent,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ChoreographerContextProvider } from '@atlassian/jira-choreographer-services/src/ChoreographerContextProvider.tsx';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { useJQL } from '../../controllers/ast/index.tsx';
import { useNumberOfSelectedValues } from '../../controllers/ast/use-selected-values/index.tsx';
import { SelectedFieldContainer } from '../../controllers/use-selected-field/index.tsx';
import FallbackSkeleton from './fallback-skeleton/index.tsx';
import { filterPopupContentEntryPoint } from './filter-popup-content/entrypoint.tsx';
import messages from './messages.tsx';
import { ErrorMessage } from './filter-popup-content/ui/error-message/index.tsx';
import type { PopupHeight, PopupWidth } from './types.tsx';
import KeyboardShortcuts from './keyboard-shortcuts/index.tsx';

const POPUP_HEIGHT: PopupHeight = '380px';
const POPUP_WIDTH: PopupWidth = '600px';

type Props = {
	scope?: JiraJqlScopeInput;
	changeboardingMessageId?: string;
};

export const FilterPopup = ({ scope, changeboardingMessageId }: Props) => {
	const jql = useJQL();
	const numberOfSelectedValues = useNumberOfSelectedValues();
	const { formatMessage } = useIntl();
	const [isFeedbackOpen, setFeedbackIsOpen] = useState(false);

	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const cloudId = useCloudId();
	const { data: orgId } = useOrgId();

	const coordinationClient = useMemo(
		() =>
			changeboardingMessageId
				? new CoordinationClient(cloudId, '/gateway/api', false, orgId, 'engagement')
				: undefined,
		[changeboardingMessageId, cloudId, orgId],
	);

	const [isMessageActive, setIsMessageActive] = useState(false);

	useEffect(() => {
		if (changeboardingMessageId) {
			coordinationClient
				?.start(changeboardingMessageId)
				.then(setIsMessageActive)
				.catch(() => setIsMessageActive(false));
		}
	}, [coordinationClient, changeboardingMessageId]);

	const closeChangeboarding = useCallback(
		(event: React.MouseEvent<Element, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			// Stop propagation avoid closing filter popup when dismissing changeboarding
			event.stopPropagation();

			if (changeboardingMessageId) {
				coordinationClient?.stop(changeboardingMessageId);
				setIsMessageActive(false);

				fireUIAnalytics(analyticsEvent, 'filterRefinementChangeboardingClosed');
			}
		},
		[coordinationClient, changeboardingMessageId],
	);

	const runtimeProps = useMemo(
		() => ({
			onCloseChangeboarding: closeChangeboarding,
			isMessageActive,
			changeboardingMessageId,
		}),
		[closeChangeboarding, isMessageActive, changeboardingMessageId],
	);

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			jqlContext: jql,
			scope,
			onFeedbackClick: setFeedbackIsOpen,
		}),
		[cloudId, jql, scope, setFeedbackIsOpen],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		filterPopupContentEntryPoint,
		entryPointParams,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);

	const onClick = useCallback(
		(_: React.MouseEvent<HTMLButtonElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			setIsOpen((open) => !open);
			fireUIAnalytics(analyticsEvent, 'filter');
		},
		[],
	);

	const onKeyboardShortcutTriggered = useCallback(() => {
		entryPointActions.load();
		setIsOpen((open) => !open);
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'pressed',
				actionSubject: 'keyboardShortcut',
			}),
			'filterPopup',
		);
	}, [createAnalyticsEvent, entryPointActions]);

	const onClose = useCallback(() => {
		// don't close the popup when the feedback modal is still open
		!isFeedbackOpen && setIsOpen(false);
	}, [isFeedbackOpen]);

	const trigger = useCallback(
		(triggerProps: TriggerProps) => (
			<SpotlightPulse radius={3} pulse={isMessageActive}>
				<Button
					interactionName="filter-popup-load-content"
					{...triggerProps}
					iconBefore={FilterIcon}
					iconAfter={() =>
						numberOfSelectedValues > 0 && (
							<Box as="span" paddingInlineEnd="space.050" paddingInlineStart="space.025">
								<Badge appearance="primary" testId="filter-refinement.ui.filter-popup.badge">
									{numberOfSelectedValues}
								</Badge>
							</Box>
						)
					}
					isSelected={isOpen || numberOfSelectedValues > 0}
					ref={mergeRefs(triggerRef, triggerProps.ref)}
					onClick={onClick}
					testId="filter-refinement.ui.filter-popup.button"
				>
					{formatMessage(messages.filterButton)}
				</Button>
			</SpotlightPulse>
		),
		[isMessageActive, isOpen, triggerRef, onClick, formatMessage, numberOfSelectedValues],
	);

	const clickRetry = useCallback(async () => {
		// opening and closing the popup to trigger the content to re-render if the retry is successful
		// otherwise content will remain showing errorFallback
		setIsOpen(false);
		entryPointActions.unload();
		await entryPointActions.load();
		setIsOpen(true);
	}, [entryPointActions]);

	const content = useCallback(
		() => (
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="jira-filter-refinement-filter-popup-content"
				packageName={PACKAGE_NAME}
				teamName={TEAM_NAME}
				errorFallback={() => (
					<Box xcss={containerStyles}>
						<ErrorMessage onClick={() => clickRetry()} errorType="fields" />
					</Box>
				)}
				fallback={<FallbackSkeleton />}
				runtimeProps={runtimeProps}
			/>
		),
		[clickRetry, entryPointReferenceSubject, runtimeProps],
	);

	return (
		<SelectedFieldContainer>
			<ChoreographerContextProvider
				isChoreographed={coordinationClient?.enableChoreographer ?? false}
			>
				{fg('filter-popup-keyboard-shortcut') && (
					<KeyboardShortcuts onShortcutTriggered={onKeyboardShortcutTriggered} />
				)}
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					trigger={trigger}
					content={content}
					placement="bottom-start"
					// unfortunately if we render to parent we are limited by page content z-index which is lower
					// than sidenav z-index and will make the popup appear below the sidenav on small screens
					// see https://jplat.atlassian.net/browse/EM-9105
					shouldRenderToParent={false}
					testId="filter-refinement.ui.filter-popup"
					messageId="filter-refinement.ui.filter-popup.popup"
					messageType="transactional"
					// shouldUseCaptureOnOutsideClick allow popup to close by triggering onClose function
					// when clicking an atlaskit dropdown (known bug)
					// https://atlassian.slack.com/archives/CFJ9DU39U/p1704237711295599?thread_ts=1703624547.647969&cid=CFJ9DU39U
					shouldUseCaptureOnOutsideClick
				/>
			</ChoreographerContextProvider>
		</SelectedFieldContainer>
	);
};

const containerStyles = xcss({
	height: POPUP_HEIGHT,
	width: POPUP_WIDTH,
	color: 'color.text.subtle',
});
