import React, { memo, useCallback, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Inline } from '@atlaskit/primitives';
import UFOSegment from '@atlaskit/react-ufo/segment';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import {
	FireUiAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
	SCREEN,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import type { ui_filterRefinement_FilterRefinement$key } from '@atlassian/jira-relay/src/__generated__/ui_filterRefinement_FilterRefinement.graphql.ts';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { AstContainer } from '../controllers/ast/index.tsx';
import { useNumberOfSelectedValues } from '../controllers/ast/use-selected-values/index.tsx';
import { SOURCE_NAME, TEAM_NAME, PACKAGE_NAME } from '../common/constants.tsx';
import { AssigneePicker } from './assignee-picker/index.tsx';
import { FilterPopup } from './filter-popup/index.tsx';
import SearchField from './search-field/index.tsx';
import type { Props } from './types.tsx';
import ErrorComponent from './error/index.tsx';

const Filter = ({ debounce = 500, defaultJql, onSearch, ...props }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnSearch = useCallback(
		(updateJql: string, fieldType: string, amountOfSelectedOptionOfFieldType: number) => {
			onSearch && onSearch(updateJql);
			fireUIAnalytics(createAnalyticsEvent({}), {
				action: 'changed',
				actionSubject: 'filterRefinement',
				actionSubjectId: 'changedFilterRefinement',
				attributes: {
					fieldType,
					amountOfSelectedOptionsOfFieldType: amountOfSelectedOptionOfFieldType,
				},
			});
		},
		[onSearch, createAnalyticsEvent],
	);

	const [handleSearchDebounced] = useDebouncedCallback(handleOnSearch, debounce);

	return (
		<JSErrorBoundary
			id={SOURCE_NAME}
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
			sendToPrivacyUnsafeSplunk
			fallback={() => <ErrorComponent />}
		>
			<AstContainer defaultJql={defaultJql} onJqlChange={handleSearchDebounced}>
				<FilterRefinement {...props} defaultJql={defaultJql} />
			</AstContainer>
		</JSErrorBoundary>
	);
};

const FilterRefinement = ({
	defaultJql,
	fragmentRef,
	projectId,
	projectKey,
	projectName,
	scope,
	searchFieldPlaceholder,
	showAssigneeFilter = true,
	changeboardingMessageId,
}: Props) => {
	const numberOfSelectedValues = useNumberOfSelectedValues();

	const data = useFragment<ui_filterRefinement_FilterRefinement$key>(
		graphql`
			fragment ui_filterRefinement_FilterRefinement on Query
			@argumentDefinitions(cloudId: { type: "ID!" }, scope: { type: "JiraJqlScopeInput" }) {
				...assigneePicker_filterRefinement @arguments(cloudId: $cloudId, scope: $scope)
			}
		`,
		fragmentRef,
	);

	const analyticsAttributes = useMemo(
		() => ({
			isAssigneeFilterRendered: showAssigneeFilter,
			numberOfSelectedValues,
			changeboardingMessageId,
		}),
		[numberOfSelectedValues, showAssigneeFilter, changeboardingMessageId],
	);

	return (
		<UFOSegment name="filter-refinement">
			<ContextualAnalyticsData
				sourceName={SOURCE_NAME}
				sourceType={SCREEN}
				attributes={{ ...analyticsAttributes, isDefaultJql: defaultJql !== undefined }}
			>
				<Inline alignBlock="center" space="space.075">
					<SearchField inputLabel={searchFieldPlaceholder} />
					{showAssigneeFilter && (
						<AssigneePicker
							fragmentRef={data}
							projectKey={projectKey}
							projectId={projectId}
							projectName={projectName}
						/>
					)}
					<FilterPopup scope={scope} changeboardingMessageId={changeboardingMessageId} />
				</Inline>
				<FireUiAnalytics actionSubject="screen" action="viewed" />
			</ContextualAnalyticsData>
		</UFOSegment>
	);
};

export default memo(Filter);
