import React, { useCallback, useRef, useMemo } from 'react';
import debounce from 'lodash/debounce';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { SearchField as SearchFieldComponent } from '@atlassian/jira-searchfield/src/index.tsx';
import { useASTActions, useAST, useTextSearchInputClause } from '../../controllers/ast/index.tsx';
import { TEXT_FIELDS } from '../../common/constants.tsx';
import KeyboardShortcuts from './keyboard-shortcuts/index.tsx';
import { update, getValue, getUnsupportedCharacters } from './utils.tsx';

type Props = {
	/**
	 * unique string identifying the input label for screen reader users and used for placeholder text
	 */
	inputLabel: string;
	/**
	 * `true` when the input should be disabled e.g. when search result is still loading
	 */
	isDisabled?: boolean;
};

type DebounceOnChangeProps = {
	targetValue: string;
	onChange: (arg1: string) => void;
};
const debounceOnChange = debounce(
	({ onChange, targetValue }: DebounceOnChangeProps) => {
		onChange(targetValue);
	},
	500,
	{
		leading: false,
		trailing: true,
	},
);

const SearchField = ({ inputLabel, isDisabled = false }: Props) => {
	const ast = useAST();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const textSearchInputClause = useTextSearchInputClause();
	const { updateJql } = useASTActions();
	const searchRef = useRef<HTMLInputElement>(null);
	const valueWithSpecialCharacters = useRef<string | undefined>();
	const value = useMemo(
		() => getValue(textSearchInputClause, valueWithSpecialCharacters.current) || '',
		[textSearchInputClause],
	);

	const handleSearch = useCallback(
		(newSearchValue: string) => {
			valueWithSpecialCharacters.current = newSearchValue;
			const updatedSearchString = update(ast, textSearchInputClause, newSearchValue);
			updateJql({ jql: updatedSearchString, fieldType: TEXT_FIELDS });

			const unsupportedCharacters = getUnsupportedCharacters(newSearchValue);
			if (unsupportedCharacters) {
				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					'filterRefinement searchWithUnsupportedCharacters',
					{
						unsupportedCharacters: [...new Set(unsupportedCharacters)],
					},
				);
			}
		},
		[ast, createAnalyticsEvent, textSearchInputClause, updateJql],
	);

	const handleChange = useCallback(
		(targetValue: string) => {
			debounceOnChange({
				onChange: handleSearch,
				targetValue,
			});
		},
		[handleSearch],
	);

	const handleFocus = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'focused',
			actionSubject: 'input',
			actionSubjectId: 'search',
			attributes: { keyboardShortcut: 'false' },
		});
	}, [createAnalyticsEvent]);

	return (
		<>
			<KeyboardShortcuts searchRef={searchRef} />
			<SearchFieldComponent
				onChange={handleChange}
				onFocus={handleFocus}
				value={value}
				placeholder={inputLabel}
				placeholderAlwaysVisible
				isCompact
				isResponsive
				inputRef={searchRef}
				isDisabled={isDisabled}
				showIconBeforeInput
				testId="filter-refinement.ui.search-field"
			/>
		</>
	);
};

export default SearchField;
