import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Heading from '@atlaskit/heading';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	title?: string;
	hasSeparator?: boolean;
	testId?: string;
	children: ReactNode;
};
export const PanelSection = ({ title, hasSeparator, children, testId }: Props) => (
	<Box paddingBlockEnd={title ? 'space.0' : 'space.050'} testId={testId}>
		{hasSeparator && <Separator />}
		{title && (
			<Box xcss={textStyles}>
				{fg('jfp_a11y_scrum_backlog_heading') ? (
					<Heading
						size={fg('view-settings-title-size-fix') ? 'xsmall' : 'medium'}
						as={fg('view-settings-title-size-fix') ? 'h3' : undefined}
					>
						{title}
					</Heading>
				) : (
					<Text as="strong">{title}</Text>
				)}
			</Box>
		)}
		{children}
	</Box>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Separator = styled.div({
	borderTop: `1px solid ${token('color.border')}`,
	marginTop: token('space.075'),
	marginBottom: token('space.075'),
});

const textStyles = xcss({ paddingTop: 'space.100', paddingBottom: 'space.100' });
